import { DeleteTwoTone, OpenInNew } from '@mui/icons-material';
import { Skeleton } from '@mui/material';
import { Avatar, Button } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { FC } from 'react';

import { useFindAgency } from '_components/Comparator/agency.repo';
import { trackAgencyRemoved } from '_components/Comparator/trackers';
import { ComparedAgency, uncompare } from '_components/Comparator/utils';
import { usePublicAppContext } from '_core/context/public-app-context';
import { goToProfile } from '_core/links/utils';

import * as S from './styles';

type Props = {
  slug: string;
  onChange: (agencies: ComparedAgency[]) => void;
};

export const AgencyCard: FC<Props> = (props) => {
  const { slug, onChange } = props;
  const { getUrl } = usePublicAppContext();
  const { t } = useTranslation(['common']);
  const { data: agency, isLoading } = useFindAgency({ slug });

  const { logo, name } = agency || {};

  const handleUncompare = (agency: ComparedAgency) => {
    trackAgencyRemoved({ page: 'comparator-list', agencySlug: slug });
    const newValues = uncompare(agency);
    onChange(newValues || []);
  };

  return isLoading ? (
    <div className="layout-row layout-align-start-center bg-neutral-100 shadow-3-secondary-500 rounded-lg m-4 p-12">
      <Skeleton variant="circular" width={50} height={50} />
      <Skeleton width={165} className="ml-16" />
    </div>
  ) : (
    <S.CardLink
      href=""
      onClick={(event) => goToProfile({ event, slug, getUrl })}
      className="layout-row layout-align-start-center border border-secondary-200 bg-neutral-100 shadow-3-secondary-500 text-secondary-900 rounded-lg m-4 p-12">
      <Avatar
        fit="contain"
        shape="square"
        alt={`${name} logo`}
        src={ImageSourceHelper.fromUrl(logo as string, {
          width: 150,
          quality: 95,
        })}
      />

      <div className="flex layout-row layout-align-start-center px-16">
        <b>{name}</b>
        <small className="ml-8 text-primary-500 hide-xs">{t('common:comparator.seeProfile')}</small>
        <OpenInNew fontSize="inherit" className="ml-4 text-primary-500" />
      </div>

      <Button
        size="xs"
        className="ml-8"
        buttonStyle="secondary"
        buttonVariant="light"
        fab={true}
        icon={<DeleteTwoTone />}
        onClick={(e) => {
          handleUncompare({ slug });
          e.preventDefault();
          e.stopPropagation();
        }}
        id={`remove-from-list-btn-${slug}`}
      />
    </S.CardLink>
  );
};
