import { AddCircleOutlineRounded } from '@mui/icons-material';
import { useTranslation } from '@sortlist-frontend/translation/ssr';

import { initCompare } from '_components/Comparator/utils';
import { useNavigationContext } from '_core/context/navigation-context';
import { usePublicAppContext } from '_core/context/public-app-context';
import { trackAgenciesSearched } from '_features/search/trackers';

import * as S from './styles';

type Props = {
  slots: number[];
  listEmpty: boolean;
  onCloseSidebar: (opened: boolean) => void;
};

export const EmptySlots = (props: Props) => {
  const { slots, onCloseSidebar, listEmpty = true } = props;
  const { t } = useTranslation(['common']);
  const { domainInfo, locale } = usePublicAppContext();
  const { asPath } = useNavigationContext();

  const goToSearch = () => {
    initCompare();
    window.location.href = `${domainInfo?.getLocalizedUrl(locale)}/search`;
  };

  const handleClick = () => {
    trackAgenciesSearched({ page: 'comparator-list', element: 'list-placeholder' });
    asPath?.replace(/\?(.*)/, '') === '/search' ? onCloseSidebar(false) : goToSearch();
  };

  return (
    <div className="layout-column">
      {slots?.map((_emptySlot, index) => {
        return (
          <div key={index}>
            <S.EmptySlot
              onClick={handleClick}
              className="layout-row layout-align-start-center rounded-lg m-4 px-16 cursor-pointer">
              <AddCircleOutlineRounded fontSize="inherit" className=" mr-8" />
              {listEmpty ? t('common:comparator.addAgency') : t('common:comparator.addAnother')}
            </S.EmptySlot>
          </div>
        );
      })}
    </div>
  );
};
