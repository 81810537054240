import { CloseRounded } from '@mui/icons-material';
import { Drawer } from '@mui/material';
import { Button } from '@sortlist-frontend/design-system';
import { BaseSyntheticEvent, useState } from 'react';

import { CompareBriefing } from '_components/Briefing/versions/CompareBriefing';
import { ComparedAgency, SingleAgency } from '_components/Comparator/utils';
import { ABOVE_INTERCOM_INDEX } from '_components/styled/shared';

import { useComparatorMessage } from '../ComparatorSidebarEmbed';
import { trackListOpened } from '../trackers';
import { List } from './List';
import { ShareLink } from './List/ShareLink';
import * as S from './styles';
import { getBriefingParams } from './utils';

export type SidebarProps = {
  opened: boolean;
  onClose: (opened: boolean) => void;
  comparedAgencies: ComparedAgency[];
  handleUncompare: (agencies: ComparedAgency[]) => void;
  briefingStarted?: boolean;
  singleAgency?: SingleAgency;
};

export const Sidebar = (props: SidebarProps) => {
  const {
    opened,
    onClose,
    comparedAgencies,
    handleUncompare,
    briefingStarted: defaultStarted = false,
    singleAgency,
  } = props;
  const [briefingStarted, setBriefingStarted] = useState<boolean>(defaultStarted);

  const { sendMessageToParent } = useComparatorMessage();

  const handleClose = (opened: boolean) => {
    sendMessageToParent('close-comparator');
    trackListOpened({ listOpened: opened });
    onClose(opened);
  };

  return (
    <Drawer
      anchor="right"
      open={opened}
      onClose={() => handleClose(!opened)}
      onClick={(e: BaseSyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      ModalProps={{
        style: { zIndex: ABOVE_INTERCOM_INDEX },
      }}
      PaperProps={{
        style: { maxWidth: '66rem' },
        className: 'width-100 width-gt-sm-50 height-100 layout-column',
      }}>
      <S.TopBar className="layout-row layout-align-start-center py-16 px-16">
        {!briefingStarted && (
          <div className="px-16 flex layout-row layout-align-start-center">
            <ShareLink agencies={comparedAgencies} />
          </div>
        )}
        <div className="px-16 flex-none">
          <Button
            fab
            size={'xs'}
            iconSize={'md'}
            buttonStyle={'secondary'}
            buttonVariant={'light'}
            icon={<CloseRounded />}
            onClick={() => handleClose(!opened)}
            aria-label="close sidebar"
            id="comparator-close-btn"
          />
        </div>
      </S.TopBar>

      {briefingStarted ? (
        <CompareBriefing
          {...getBriefingParams(comparedAgencies)}
          onStop={() => setBriefingStarted(false)}
          onClose={() => handleClose(!opened)}
          singleAgency={singleAgency}
        />
      ) : (
        <List
          agencies={comparedAgencies}
          handleUncompare={handleUncompare}
          onCompareBriefingStart={() => {
            setBriefingStarted(true);
          }}
          onCloseSidebar={handleClose}
        />
      )}
    </Drawer>
  );
};
