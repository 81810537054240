import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const CardLink = styled.a`
  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    &:hover {
      border-color: ${COLORS.SECONDARY_500};
    }
  }
`;
