const PERSONAL_EMAIL_DOMAINS = [
  'aol.com',
  'bluewin.ch',
  'email.com',
  'email.de',
  'free.fr',
  'freenet.de',
  'gmail.com',
  'googlemail.com',
  'gmail.es',
  'gmx.at',
  'gmx.com',
  'gmx.de',
  'gmx.fr',
  'gmx.net',
  'hotmail.com',
  'hotmail.fr',
  'hotmail.it',
  'hotmail.co.uk',
  'icloud.com',
  'cloud.es',
  'me.com',
  'mac.com',
  'laposte.net',
  'libero.it',
  'live.be',
  'live.co.uk',
  'live.com',
  'live.de',
  'live.fr',
  'live.it',
  'live.nl',
  'mail.com',
  'mail.ru',
  'mail.de',
  'mailo.com',
  'msn.com',
  'orange.fr',
  'outlook.com',
  'outlook.fr',
  'proton.me',
  'protonmail.com',
  'qq.com',
  'rediffmail.com',
  'sfr.fr',
  'skynet.be',
  't-online.de',
  'telenet.be',
  'wanadoo.fr',
  'web.de',
  'yahoo.com',
  'yahoo.fr',
  'yahoo.co.uk',
  'yahoo.co.br',
  'yahoo.co.in',
  'yahoo.ro',
  'yandex.ru',
  'ymail.com',
];

export const isPersonalEmail = (email?: string) => {
  const domain = email?.split('@')[1];
  if (domain == null) return false;
  return PERSONAL_EMAIL_DOMAINS.includes(domain);
};
