import { useQuery } from '@tanstack/react-query';
import { AxiosRequestConfig } from 'axios';

import { api } from '_core/api/api-nextjs-proxy';
import { usePublicAppContext } from '_core/context/public-app-context';
import { AgencyProps } from '_pages/api/proxy/agencies/find-agency';
import { AgencyServicesProps } from '_pages/api/proxy/agencies/find-agency-with-services';

type VariableProps = {
  slug: string;
};

export const cacheKey = {
  findAgency: '/api/proxy/agencies/find-agency',
  findAgencyWithServices: '/api/proxy/agencies/find-agency-with-services',
};

const urls = {
  findAgency: '/api/proxy/agencies/find-agency',
  findAgencyWithServices: '/api/proxy/agencies/find-agency-with-services',
};

export const agencyRepo = {
  findAgency: async (variables: VariableProps, config?: AxiosRequestConfig): Promise<AgencyProps> => {
    const returnObject = await api.post(urls.findAgency, variables, config);
    return returnObject?.data?.data?.agency;
  },
  findAgencyWithServices: async (
    variables: VariableProps,
    config?: AxiosRequestConfig,
  ): Promise<AgencyServicesProps> => {
    const returnObject = await api.post(urls.findAgencyWithServices, variables, config);
    return returnObject?.data?.data?.agency;
  },
};

export const useFindAgency = (variables: VariableProps) => {
  const { domainInfo } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';
  const { slug } = variables;

  return useQuery({
    queryKey: [cacheKey.findAgency, slug],
    queryFn: () => agencyRepo.findAgency(variables, { baseURL }),
  });
};

export const useAgencyWithServices = (variables: VariableProps) => {
  const { domainInfo } = usePublicAppContext();
  const baseURL = domainInfo?.getOriginUrl() ?? '';
  const { slug } = variables;

  return useQuery({
    queryKey: [cacheKey.findAgencyWithServices, slug],
    queryFn: () => agencyRepo.findAgencyWithServices(variables, { baseURL }),
  });
};
